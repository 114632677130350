import 'bootstrap/dist/css/bootstrap-grid.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import './src/styles/global.scss'

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  if (!prevRouterProps) {
    return true
  }

  if (prevRouterProps.location.pathname === `/blog/` && location.pathname === `/blog/`) {
    return false
  }

  return true
}